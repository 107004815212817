#modalWrapper>div>div {
  border: none;
  box-sizing: border-box;

  @media screen and (max-width: 425px) {
    width: 360px;
  }

  @media (max-width: 375px) {
    width: 340px;
  }
}
