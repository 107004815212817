#top-dropdown {
  align-items: center;
  position: absolute;
  z-index: 2;
  top: -49px;
  right: 0;
  transition: all 0.3s;

  label {
    padding-right: 0;
  }

  ul {
    background: #ffffff;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);

    li:first-child {
      display: none;
    }
  }
}

#edit-classroom {
  position: absolute;
  z-index: 2;
  top: -60px;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.sticky-bar {
  #top-dropdown,
  #edit-classroom {
    top: -45px;
  }
}

#low-dropdown {
  z-index: 1;
  position: absolute;
  right: 0;

  label {
    height: 65px;

    padding-right: 0;
  }

  ul {
    top: 65px;
    background-color: #ffffff;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);

    li {
      font-size: 16px;
      color: #999999;
    }

    li:hover {
      color: #1a73e8;
    }
  }
}

.inactive {
  font-family: CVSourceSans, sans-serif;
  font-size: 16px;
  color: #999999;
}

.nameButton {
  width: 140px;
  height: 40px;
  border-radius: 40px;
  font-size: 16px;
}

.editNameSection {
  position: absolute;
  bottom: 84px;
  display: flex;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 1300px) {
    width: calc(100% - 150px);
    left: 150px;
  }

  @media screen and (width: 768px) {
    width: calc(100% - 96px);
    left: 96px;
  }
}

.nameInput {
  flex: 1;
  padding-left: 10px;
  font-size: 30px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ddd;
}

.saveButton {
  right: 150px;
  border: none;
  background-color: #1a73e8;
  color: #fff;
}

.cancelButton {
  border: 1px solid #1a73e8;
  background-color: #fff;
  color: #1a73e8;
}

.adjustSpan {
  display: flex;
}

.mobileButtonIcon {
  display: none !important;
}

.virtualNameButton {
  left: 0;
  position: absolute;
  bottom: 114px;
  font-size: 30px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  background: transparent;
  color: transparent;

  @media screen and (max-width: 1300px) {
    left: 148px;
  }
  @media screen and (width: 768px) {
    left: 92px;
  }
}

@media screen and (max-width: 767px) {
  #top-dropdown,
  #edit-classroom {
    top: -90px;
  }
  .sticky-bar {
    #edit-classroom {
      top: -38px;
    }
  }
  .header-dropdown label {
    padding: 0;
  }
  .nameButton {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    font-size: 0;
  }
  .editNameSection {
    flex-direction: column;
    left: 86px;
    bottom: 50px;
  }
  .nameInput {
    margin: 0 10px;
    font-size: 18px;
    padding-left: 4px;
  }
  .saveButton {
    right: -40px;
    margin-right: 4px;
  }
  .cancelButton {
    border: none;
  }
  .adjustSpan {
    display: none;
  }
  .mobileButtonIcon {
    display: block !important;
  }
  .virtualNameButton {
    left: 92px;
    bottom: 80px;
    font-size: 20px;
  }
}
