.react-calendar
  border: none
  width: 100%

  &__month-view__weekdays
    text-transform: none

    abbr[title]
      text-decoration: none

  &__month-view__days__day--weekend
    color: black
    background-color: rgba(216, 216, 216, 0.2)

  &__navigation
    &__arrow
      color: #1A73E8

  &__tile--now
    background-color: white

  &__tile--active
    color: white
    background: #006edc
